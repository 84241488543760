<template>
  <BaseModal
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    :busy="saving"
    okLabel="zapisz"
    @ok="save"
  >
    <template #header>
      Dodawanie pracownika
    </template>
    <template #default>
      <EmployeeForm
        :employee="employeeCache"
        :validationErrors="{ errors: saveErrors, warnings: {} }"
        @formChanged="setEmployeeData"
      />
    </template>
  </BaseModal>
</template>

<script>
import { errorHandler } from '../../mixins/errorHandler';
import EmployeeService from '../../services/EmployeeService';
import EmployeeForm from '../employee/EmployeeForm.vue';

export default {
  name: 'EmployeeAdd',
  components: { EmployeeForm },
  mixins: [errorHandler],
  data() {
    return {
      employeeCache: {},
      saveErrors: {},
      saving: false,
    };
  },
  methods: {
    setEmployeeData(customerForm) {
      Object.assign(this.employeeCache, customerForm);
    },
    save() {
      this.saving = true;
      EmployeeService.addEmployee(this.employeeCache)
        .then((response) => {
          this.saveErrors = {};
          this.$store.dispatch('notifications/add', { type: 'success', message: 'Pracownik został dodany'});
          this.$router.push({ name: 'Employee', params: { id: response.data.newEmployeeId } });
        })
        .catch(error => {
          this.saveErrors = this.resolveError(error);
          this.saving = false;
        });
    },
  },
};
</script>
