<template>
  <div>
    <div id="wrapper"
      @click="goTo({ name: 'Employee', params: { id: employee.id }})"
      class="mb-2 py-0.5 pl-0.5 flex flex-wrap lg:items-stretch border border-gray-200 dark:border-gray-700 dark:hover:border-gray-600 rounded shadow hover:shadow-inner cursor-pointer"
    >
      <div id="employee-name"
        class="flex items-center w-full lg:w-5/12 xl:w-1/3 2xl:w-1/5 px-2 xl:pl-1 xl:pr-2"
        :title="`status: ${employee.status_description}`"
      >
        <FontAwesomeIcon
          :icon="employeeStatus.icon"
          size="sm"
          class="mr-1"
          :class="employeeStatus.color"
        />
        <span @click.stop>
          <router-link :to="{ name: 'Employee', params: { id: employee.id }}" class="tw--link">{{ employee.name }}</router-link>
        </span>
      </div>

      <div id="employee-type" class="flex items-center w-1/2 lg:w-4/12 xl:w-1/3 2xl:w-1/5 px-2 xl:pl-1 xl:pr-2" title="stanowisko">
        <FontAwesomeIcon icon="briefcase" size="sm" class="text-muted mr-1" />
        <span>{{ employee.empl_type }}</span>
      </div>

      <div id="employee-provision-type" class="flex items-center justify-end lg:w-3/12 xl:w-1/3 2xl:justify-start w-1/2 2xl:w-1/5 px-2 xl:pl-1 xl:pr-2" title="poziom prowizyjny">
        <FontAwesomeIcon icon="coins" size="sm" class="text-muted mr-1" />
        <span>{{ employee.prov_lvl_name }}</span>
      </div>

      <div id="employee-contact" class="flex flex-wrap items-center justify-between w-full 2xl:w-2/5 px-2 xl:pl-1 xl:pr-2">
        <div>
          <FontAwesomeIcon icon="at" class="text-muted mr-1" />&nbsp;
          <a @click.stop v-if="employee.email" :href="`mailto:${employee.email}`" class="tw--link lowercase">{{ employee.email }}</a>
          <span v-else class="text-muted"><em>nie podano</em></span>
        </div>
        <div>
          <FontAwesomeIcon icon="phone-alt" class="text-muted mr-1" />&nbsp;
          <a @click.stop v-if="employee.phone" :href="`tel:${employee.phone}`" class="tw--link">{{ employee.phone | phoneNo }}</a>
          <span v-else class="text-muted"><em>nie podano</em></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'employeeListItem',
  props: ['employee'],
  computed: {
    employeeStatus() {
      if (this.employee.status_id === '40') {
        return { icon: 'user-slash', color: 'text-red-400 dark:text-red-600' };
      } else if (this.employee.status_id === '30') {
        return { icon: 'user-times', color: 'text-red-400 dark:text-red-600' };
      } else if (this.employee.status_id === '20') {
        return { icon: 'user-lock', color: 'text-yellow-400 dark:text-yellow-600' };
      } else if (this.employee.status_id === '10') {
        return { icon: 'user-clock', color: 'text-yellow-400 dark:text-yellow-600' };
      }
      return { icon: 'user-check', color: 'text-green-400 dark:text-green-600' };
    },
  },
  methods: {
    goTo(route) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push(route);
      }
    },
  },
};
</script>
