<template>
  <div>
    <EmployeeAdd v-if="addEmployeeModal && showAddEmployee" @closeModal="closeAddEmployeeModal" />
    <!-- <AddEmployeeModal v-if="addEmployeeModal && showAddEmployee" @closeModal="closeAddEmployeeModal"></AddEmployeeModal> -->
    <div id="filters" class="bg-white dark:bg-gray-900 p-3 mb-2 rounded-lg shadow-lg">
      <div id="order-basic-filters" class="flex flex-wrap mb-2 gap-2 items-center justify-between">
        <!-- Search Button with addons -->
        <div class="flex flex-none items-center order-1">
          <input
            id="filterInput"
            class="border-gray-300 dark:border-gray-600 rounded-r-none"
            :class="{'bg-yellow-100 dark:bg-yellow-800': activeFilters.search.length > 0}"
            v-model="activeFilters.search"
            type="text"
            placeholder="wpisz fragment..."
          >
          <button
            @click="activeFilters.search = ''"
            class="tw--btn tw--btn-secondary rounded-none border-t border-b border-gray-300 dark:border-gray-600"
            type="button"
            :disabled="activeFilters.search === ''"
            title="wyczyść"
          ><FontAwesomeIcon icon="times"/></button>
          <button
            @click="setUrl"
            class="tw--btn border border-blue-600 dark:border-blue-900 rounded-l-none"
            type="button"
            :disabled="activeFilters.search === ''"
          >
            Szukaj
          </button>
        </div>
        <!-- End Search Button -->

        <div class="flex flex-shrink order-2 lg:order-3 gap-0.5">
          <button @click="resetFilters()" class="tw--btn tw--btn-filter shadow" title="wyczyść wszystkie filtry">
            <FontAwesomeIcon icon="times" size="xs" /> <FontAwesomeIcon icon="filter" />
          </button>
          <button @click="initList()" class="tw--btn tw--btn-filter shadow" title="odśwież dane z serwera">
            <FontAwesomeIcon icon="sync-alt" size="xs" /> <FontAwesomeIcon icon="database" />
          </button>
        </div>
      </div>

      <div class="flex flex-wrap">

        <div id="order-adv-filters-1" class="flex flex-col w-full md:w-auto md:flex-row md:flex-wrap items-start gap-4 mb-2">
          <BaseFilterSelectWithAddon class="w-full md:min-w-max flex-1" @input="setUrl" v-model="activeFilters.type" label="Stanowisko" :options="emplTypeList" />
          <BaseFilterSelectWithAddon class="w-full md:min-w-max flex-1" @input="setUrl" v-model="activeFilters.prov_lvl" label="Poziom prow." :options="emplProvLvlList" />
          <BaseFilterSelectWithAddon class="w-full md:min-w-max flex-1" @input="setUrl" v-model="activeFilters.status" label="Status" :options="statusFilter" />
        </div>

        <div class="flex flex-grow w-full md:w-auto gap-4 justify-end">
          <div class="flex rounded-sm items-center text-xs">
            <div class="px-2 py-1 rounded-l-sm bg-gray-800 text-gray-200 dark:bg-black dark:text-gray-400 font-semibold">Znaleziono</div>
            <div class="px-2 py-1 rounded-r-sm bg-blue-300 text-gray-700 dark:bg-blue-900 dark:text-gray-400 font-semibold">{{ count.all }} pozycji</div>
          </div>
        </div>
      </div>

    </div>

    <div id="customer-list" class="bg-white dark:bg-gray-900 p-3 rounded-lg shadow-xl">
      <div class="flex flex-wrap justify-between">
        <button @click="addEmployeeModal = true" class="tw--btn inline-block mb-2">
          <i class="fas fa-user-plus"></i>&nbsp;Dodaj pracownika
        </button>

        <SortList v-if="sortedBy.field !== ''" @sort="sortItemsBy" :fields="sortFields" :selected="sortedBy" />

      </div>
      <div class="flex flex-col">
        <EmployeeListItem v-for="employee in filteredItems" :employee="employee" :key="employee.id" />
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { errorHandler } from '../mixins/errorHandler';
import EmployeeService from '../services/EmployeeService';
import EmployeeListItem from '../components/EmployeeListItem.vue';
import EmployeeAdd from '../components/employee/EmployeeAdd.vue';
import SortList from '../components/SortList.vue';

export default {
  name: 'EmployeeList',
  components: {
    EmployeeListItem,
    SortList,
    EmployeeAdd,
  },
  mixins: [errorHandler],
  data() {
    return {
      employees: [],
      sortedBy: {
        field: '',
        dir: 1,
      },
      statusFilter: [
        { id: '1', name: 'Aktywny' },
        // { id: '10', name: 'Nieaktywowany' },
        { id: '20', name: 'Zablokowany' },
        { id: '30', name: 'Wyłączony' },
        // { id: '40', name: 'Usunięty' },
      ],
      activeFilters: {
        search: '',
        status: '1',
        type: '',
        prov_lvl: '',
      },
      sortFields: [
        { id: 'name', name: 'nazwisko / nazwa' },
        { id: 'phone', name: 'telefon' },
        { id: 'email', name: 'email'},
        { id: 'empl_type', name: 'stanowisko' },
        { id: 'prov_lvl_name', name: 'poziom prow.' },
      ],
      addEmployeeModal: false,
    };
  },
  computed: {
    ...mapGetters([
      'currentUserType',
    ]),
    ...mapState('employees', [
      'emplTypeList',
      'emplFormList',
      'emplManagerList',
      'emplProvLvlList',
    ]),
    showAddEmployee() {
      return this.currentUserType === 'root' || this.currentUserType === 'admin';
    },
    filteredItems() {
      if (Object.keys(this.activeFilters).length > 0) {
        const checkFilters = (item) => {
          let isMatch = true;
          Object.keys(this.activeFilters).forEach((key) => {
            if (this.activeFilters[key] !== '') {
              if (key === 'search') {
                const search = this.activeFilters[key].toLowerCase();
                if (!item.name.toLowerCase().includes(search) && !item.email.toLowerCase().includes(search) && !item.company_name.toLowerCase().includes(search)) {
                  isMatch = false;
                }
              } else if (key === 'status' && this.activeFilters[key] !== item.status_id) {
                isMatch = false;
              } else if (key === 'type' && this.activeFilters[key] !== item.empl_type_id) {
                isMatch = false;
              } else if (key === 'prov_lvl' && this.activeFilters[key] !== item.provision_lvl) {
                isMatch = false;
              }
            }
          });
          return isMatch;
        };
        return this.employees.filter(checkFilters);
      }
      return this.employees;
    },
    count() {
      return { filtered: this.filteredItems.length, all: this.employees.length };
    },
  },
  created() {
    if (this.emplTypeList.length === 0) this.$store.dispatch('employees/getEmplTypeList').catch(error => this.resolveError(error));
    if (this.emplFormList.length === 0) this.$store.dispatch('employees/getEmplFormList').catch(error => this.resolveError(error));
    if (this.emplManagerList.length === 0) this.$store.dispatch('employees/getManagerList').catch(error => this.resolveError(error));
    if (this.emplProvLvlList.length === 0) this.$store.dispatch('employees/getEmplProvList').catch(error => this.resolveError(error));
    this.initList();
  },
  methods: {
    initList() {
      this.$store.dispatch('setLoadingData', true);
      EmployeeService.getEmployees()
        .then((response) => {
          this.employees = response.data.result;
          if (this.$route.query.sf !== undefined && ['name', 'phone', 'email', 'empl_type', 'prov_lvl_name'].includes(this.$route.query.sf)) {
            this.sortedBy.field = this.$route.query.sf;
            if (this.$route.query.sd !== undefined) {
              this.sortedBy.dir = Number(this.$route.query.sd) === -1 ? -1 : 1;
            } else {
              this.sortedBy.dir = 1;
            }
          } else {
            this.sortedBy.field = 'name';
            this.sortedBy.dir = 1;
          }
          if (this.$route.query.search !== undefined) {
            this.activeFilters.search = decodeURI(this.$route.query.search);
          }
          if (this.$route.query.status !== undefined) {
            this.activeFilters.status = decodeURI(this.$route.query.status);
          }
          if (this.$route.query.type !== undefined) {
            this.activeFilters.type = decodeURI(this.$route.query.type);
          }
          this.sortItemList();
          this.setUrl();
          this.$store.dispatch('setLoadingData', false);
        })
        .catch((error) => {
          this.$store.dispatch('setLoadingData', false);
          this.resolveError(error); //mixin
        });
    },
    sortItemsBy(object) {
      this.sortedBy.field = object.field;
      this.sortedBy.dir = object.dir;
      this.sortItemList();
      this.setUrl();
    },
    sortItemList() {
      const compareNumbers = (a, b) => this.sortedBy.dir * (a[this.sortedBy.field] - b[this.sortedBy.field]);
      const compareText = (a, b) => {
        const aName = a[this.sortedBy.field].toLowerCase();
        const bName = b[this.sortedBy.field].toLowerCase();
        let tmpDir = 0;
        if (aName < bName) {
          tmpDir = -1;
        } else if (aName > bName) {
          tmpDir = 1;
        }
        return this.sortedBy.dir * tmpDir;
      };
      if (['id'].includes(this.sortedBy.field)) {
        this.employees.sort(compareNumbers);
      } else {
        this.employees = this.employees.sort(compareText);
      }
    },
    sortedIcon(field) {
      if (this.sortedBy.field === field) {
        return this.sortedBy.dir === 1 ? 'has-text-success fa-sort-amount-up' : 'has-text-success fa-sort-amount-down';
      }
      return 'fa-sort';
    },
    resetFilters() {
      Object.keys(this.activeFilters).forEach((key) => {
        this.activeFilters[key] = '';
      });
      this.setUrl();
    },
    setUrl() {
      const query = {
        sf: this.sortedBy.field,
        sd: `${this.sortedBy.dir}`,
      };
      const queryFilters = {};
      Object.keys(this.activeFilters).forEach((key) => {
        queryFilters[key] = encodeURI(this.activeFilters[key]);
      });
      Object.assign(query, queryFilters);
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({ name: 'Employees', query });
      }
    },
    closeAddEmployeeModal() {
      this.addEmployeeModal = false;
    },
  },
};
</script>
